exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-news-archive-js": () => import("./../../../src/pages/about/news-archive.js" /* webpackChunkName: "component---src-pages-about-news-archive-js" */),
  "component---src-pages-acts-index-js": () => import("./../../../src/pages/acts/index.js" /* webpackChunkName: "component---src-pages-acts-index-js" */),
  "component---src-pages-catalog-index-js": () => import("./../../../src/pages/catalog/index.js" /* webpackChunkName: "component---src-pages-catalog-index-js" */),
  "component---src-pages-debug-index-js": () => import("./../../../src/pages/debug/index.js" /* webpackChunkName: "component---src-pages-debug-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-release-js": () => import("./../../../src/templates/release.js" /* webpackChunkName: "component---src-templates-release-js" */),
  "component---src-templates-static-js": () => import("./../../../src/templates/static.js" /* webpackChunkName: "component---src-templates-static-js" */)
}

